<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              阳历生日：
              <strong>
                {{ currentYear }}-{{ currentMonth }}-{{ currentDay }}
              </strong>
            </slot>
          </div>
          <div class="modal-body">
            <div class="picker-group">
              <scroll-picker
                :options="years"
                v-model="currentYear"
              ></scroll-picker>
              <scroll-picker
                :options="months"
                v-model="currentMonth"
              ></scroll-picker>
              <scroll-picker
                :options="days"
                v-model="currentDay"
              ></scroll-picker>
              <!-- <VueScrollPicker :options="years" v-model="currentYear" /> -->
              <!-- <VueScrollPicker :options="months" v-model="currentMonth" />
              <VueScrollPicker :options="days" v-model="currentDay" /> -->
            </div>
            <slot name="body-slot"></slot>
          </div>

          <div class="modal-footer">
            <button class="modal-button modal-cancel" @click="cancel()">
              取消
            </button>
            <button class="modal-button modal-submit" @click="submit()">
              确定
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { ScrollPicker } from 'vue-scroll-picker'

import 'vue-scroll-picker/dist/style.css'

export default {
  props: {
    show: Boolean,
    date: Object,
    year: Number,
    month: Number,
    day: Number,
  },
  data() {
    return {
      currentYear: this.year,
      currentMonth: this.month,
      currentDay: this.day,
      delayShow: false,
    }
  },
  components: {
    ScrollPicker,
  },
  watch: {
    show() {
      this.currentYear = this.year || 1990
      this.currentMonth = this.month || 6
      this.currentDay = this.day || 15
    },
  },
  mounted: function () {},
  computed: {
    years() {
      const currYear = new Date().getFullYear() - 9
      const lastYear = 1900
      return Array.from(
        { length: currYear - lastYear + 1 },
        (_, index) => lastYear + index
      ).reverse()
    },
    months() {
      return Array.from({ length: 12 }, (_, index) => index + 1)
    },
    days() {
      const lastDay = new Date(this.currentYear, this.currentMonth, 0).getDate()
      return Array.from({ length: lastDay }, (_, index) => index + 1)
    },
  },
  methods: {
    submit: function () {
      this.$emit(
        'modal-submit',
        this.currentYear,
        this.currentMonth,
        this.currentDay
      )
      this.$emit('close')
    },
    cancel: function () {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  /* transition: opacity 0.3s ease; */
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 0px 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  /* transition: all 0.3s ease; */
}
.modal-header {
  padding: 10px 0px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  font-size: 24px;
  display: block;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0px 20px;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid #ddd;
}
.modal-button {
  border: none;
  background: none;
  padding: 10px 0;
  flex: 1;
  font-size: 24px;
}

.modal-button:active {
  background: #ccc;
}
.modal-cancel {
  color: black;
}
.modal-submit {
  border-left: 1px solid #ddd;
  color: #007bff;
}

/*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  /* -webkit-transform: scale(1.1);
  transform: scale(1.1); */
}
</style>
<style>
.picker-group {
  display: flex;
  height: 5em;
  overflow: hidden;
  font-size: 32px;
  align-items: center;
}
.vue-scroll-picker-layer .top {
  height: calc(50% - 0.5em);
}
.vue-scroll-picker-layer .bottom {
  height: calc(50% - 0.75em);
}
.vue-scroll-picker-item {
  line-height: 1.5em;
}
.-selected {
  color: #007bff;
}
</style>
