<template>
  <div class="home">
    <div class="key-vision-desktop">
      <img src="@/assets/image/love-bn-01.jpg" width="100%" />
    </div>
    <div class="key-vision-mobile">
      <img src="@/assets/image/love-bn-02.jpg" width="100%" />
    </div>
    <!-- 测算表单 Section1 -->
    <div class="container true-love-wrapper">
      <section>
        <h1 class="text-center heading1">感情困惑，一次帮你解答</h1>
        <h4 class="text-center heading5">
          已为 <span class="text-danger">90377</span> 位用户测算
        </h4>
        <div class="w-100 row" style="margin: 140px auto 140px">
          <div class="col-12 col-lg-7">
            <img src="@/assets/image/love-main.png" class="img-fluid" alt="" />
          </div>
          <form class="office_form col-12 col-lg-5 p-4 rounded-4 bg-white">
            <div class="office_form-control">
              <label class="form_label" for="input_name">姓名</label>
              <input
                class="office_input"
                type="text"
                id="input_name"
                placeholder="您的姓名"
                v-model="name"
              />
            </div>
            <div class="office_form-control form_birth">
              <label class="form_label" for="input_date">生日</label>
              <input
                type="text"
                class="office_input"
                id="input_date"
                v-model="birthday"
              />
              <button class="form_birth-btn" type="button">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.2 4V3C15.2 2.44772 15.6477 2 16.2 2C16.7523 2 17.2 2.44772 17.2 3V4.00491C19.3162 4.10912 21 5.85793 21 8V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V8C3 5.79086 4.79086 4 7 4H7.2V3C7.2 2.44772 7.64772 2 8.2 2C8.75228 2 9.2 2.44772 9.2 3V4H11.2V3C11.2 2.44772 11.6477 2 12.2 2C12.7523 2 13.2 2.44772 13.2 3V4H15.2ZM15.2 6H13.2C13.2 6.55228 12.7523 7 12.2 7C11.6477 7 11.2 6.55228 11.2 6H9.2C9.2 6.55228 8.75228 7 8.2 7C7.64772 7 7.2 6.55228 7.2 6H7C5.89543 6 5 6.89543 5 8V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V8C19 6.96291 18.2106 6.11019 17.2 6.00987C17.1947 6.55761 16.749 7 16.2 7C15.6477 7 15.2 6.55228 15.2 6ZM9 12C8.44772 12 8 11.5523 8 11C8 10.4477 8.44772 10 9 10H16C16.5523 10 17 10.4477 17 11C17 11.5523 16.5523 12 16 12H9ZM9 16C8.44772 16 8 15.5523 8 15C8 14.4477 8.44772 14 9 14H14C14.5523 14 15 14.4477 15 15C15 15.5523 14.5523 16 14 16H9Z"
                    fill="#6C757D"
                  />
                </svg>
              </button>
              <div class="date-input-wrapper" @click="showModal = true"></div>
            </div>
            <div class="office_form-control">
              <label class="form_label" for="input_time">时辰</label>
              <select class="form_select" id="input_time" v-model="birthHour">
                <option value="0">不确定</option>
                <option value="0">早子时 00:00-00:59</option>
                <option value="1">丑时 01:00-02:59</option>
                <option value="3">寅时 03:00-04:59</option>
                <option value="5">卯时 05:00-06:59</option>
                <option value="7">辰时 07:00-08:59</option>
                <option value="9">巳时 09:00-10:59</option>
                <option value="11">午时 11:00-12:59</option>
                <option value="13">未时 13:00-14:59</option>
                <option value="15">申时 15:00-16:59</option>
                <option value="17">酉时 17:00-18:59</option>
                <option value="19">戌时 19:00-20:59</option>
                <option value="21">亥时 21:00-22:59</option>
                <option value="23">晚子时 23:00-23:59</option>
              </select>
            </div>
            <div class="office_form-control">
              <label class="form_label" for="input_gender">性别</label>
              <select class="form_select" id="input_gender" v-model="gender">
                <option value="1">男</option>
                <option value="2">女</option>
              </select>
            </div>
            <p class="text-center">为了求测试结果准确，请仔细核对资讯</p>
            <div class="btn btn-buy btn-block" @click="submitToPreview()">
              立即测算
            </div>
            <p class="text-center check-history" @click="linkToHistory()">
              查看历史订单 >>
            </p>
          </form>
          <!-- from 表单 -->
        </div>
      </section>
      <!-- 四大区块 Section2 -->
      <section style="margin: 140px auto 140px">
        <h2 class="text-center py-5">四大方向指点您如何突破感情困境</h2>
        <div class="row row-cols-2 row-cols-md-4">
          <div class="col-12 col-md-3 px-2 mb-4">
            <div class="bg-white shadow-sm rounded-5 py-5">
              <img
                class="w-50 d-block mx-auto"
                src="@/assets/image/love-woman.png"
                alt=""
              />
              <h3 class="h2 text-center py-4">真命指南</h3>
              <p class="text-justify opacity-75 px-4">
                茫茫人海真命何处寻，锁定真命天子的特征，在对的方向找到对的人。
              </p>
            </div>
          </div>
          <div class="col-12 col-md-3 px-2 mb-4">
            <div class="bg-white shadow-sm rounded-5 py-5">
              <img
                class="w-50 d-block mx-auto"
                src="@/assets/image/love-man.png"
                alt=""
              />
              <h3 class="h2 text-center py-4">个性分析</h3>
              <p class="text-justify opacity-75 px-4">
                要怎么跟我的真命好好相处呢？他会是什么个性的人呢？是温暖善良，还是聪明过人呢？
              </p>
            </div>
          </div>
          <div class="col-12 col-md-3 px-2 mb-4">
            <div class="bg-white shadow-sm rounded-5 py-5">
              <img
                class="w-50 d-block mx-auto"
                src="@/assets/image/love-img03.png"
                alt=""
              />
              <h3 class="h2 text-center py-4">爱情运势</h3>
              <p class="text-justify opacity-75 px-4">
                单身的你何时能顺利脱单，脱单后又该如何经营一段好的姻缘，安然渡过爱情的起起落落。
              </p>
            </div>
          </div>
          <div class="col-12 col-md-3 px-2 mb-4">
            <div class="bg-white shadow-sm rounded-5 py-5">
              <img
                class="w-50 d-block mx-auto"
                src="@/assets/image/love-img04.png"
                alt=""
              />
              <h3 class="h2 text-center py-4">幸福御守</h3>
              <p class="text-justify opacity-75 px-4">
                我应该做哪些努力，让幸福到手，感情运势大吉，守护我的美满人生。
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- 简少年老师介绍 Section4 -->
      <div
        class="d-flex flex-column flex-md-row justify-content-center align-bottom"
      >
        <div class="col-12 col-md-6">
          <img src="@/assets/image/teacher2.png" alt="" />
        </div>
        <div class="col-12 col-md-6 mt-auto mb-5">
          <h2 class="h1 py-4 text-center text-md-start">吴明光老师介绍</h2>
          <p class="paragraph text-center text-md-start mb-4">
            吴明光老师，13年命理占卜经验，出身术数世家。<br />
            师承台湾王派风水、紫微斗数占验派、萧湘派面相学、擅阴阳宅风水学。<br />
            实战派命理师，经过十多年帮无数人服务解惑，已成为两岸三地各大企业主争相邀请的命理大师，于2015年创立「桃桃喜」。<br />
            擅长运用命理工具来协助每一位朋友跟伙伴从分析现况开始，经由了解运势方向，到作出最有利的决定，成功帮助无数客户朋友度过爱情，工作以及人生的难关。
          </p>
          <a
            href=""
            class="buy d-block d-md-inline text-decoration-none mx-auto text-center text-md-start"
            >立即测算</a
          >
        </div>
      </div>
    </div>
    <!-- 解说 Section6 -->
    <section class="py-5" style="background-color: #ecdfd5">
      <div class="container">
        <div class="py-3">
          <h3 class="h3 pb-2">紫微斗数的由来</h3>
          <p class="opacity-75 texr-justify border-top">
            紫微斗数相传为五代方士陈抟（872年—989年）观星所创。在古代被尊称为「帝王学」，并在皇宫中设有「钦天监」对其进行研究，能接触到紫微斗数的人只限于皇族中人或是有皇帝钦点的官员。
            后来随著国民党退守台湾，有关于紫微斗数的书籍也随之流入台湾。致使紫微斗数在台湾的传承度非常完整。大师高手辈出！
          </p>
        </div>
        <div class="py-3">
          <h3 class="h3 pb-2">紫微斗数测算的准度</h3>
          <p class="opacity-75 texr-justify border-top">
            紫微斗数经过千年的演进,星曜安宫的计算方法相当完备,
            能经由个人命盘精准判断人生大运, 小限, 流年,
            流月以及作用在12宫位上的事件, 包括工作, 感情, 财运, 婚恋, 身体,
            家族等等全方面, 巨细靡遗的解析。
          </p>
        </div>
        <div class="py-3">
          <h3 class="h3 pb-2">本测算可以给你的帮助</h3>
          <p class="opacity-75 texr-justify border-top">
            本测算针对人生最常遭遇的几大困扰, 如感情困扰,工作运势,
            流年运势上的起伏,依照你个人命盘进行解析, 然后给您最有帮助的答案,
            细节可以参考各大测验的内页说明。
          </p>
        </div>
      </div>
    </section>
    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <date-picker-modal
        :show="showModal"
        :year="currentYear"
        :month="currentMonth"
        :day="currentDay"
        @modal-submit="modalSubmit"
        @close="showModal = false"
      >
      </date-picker-modal>
    </Teleport>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import Teleport from 'vue2-teleport'

import DatePickerModal from '@/components/DatePickerModal.vue'

import { SHI_CHEN } from '@/constants/shi-chen-list'

export default {
  name: 'HomeView',
  data() {
    return {
      name: null,
      currentYear: null,
      currentMonth: null,
      currentDay: null,
      birthHour: null,
      gender: null,
      showModal: false,
    }
  },
  components: { DatePickerModal, Teleport },
  mounted: function () {
    // eslint-disable-next-line no-undef
    // var birthday = new datePicker()
    // birthday.init({
    //   trigger: '#input_date',
    //   type: 'date',
    //   onSubmit: function () {},
    //   onClose: function () {},
    // })
  },
  methods: {
    submitToPreview: function () {
      if (this.formValid) {
        var birthday = moment(this.birthday)
        var payload = {}

        payload.name = this.name
        payload.gender = parseInt(this.gender)
        payload.birthdayYear = this.currentYear
        payload.birthdayMonth = this.currentMonth
        payload.birthdayDate = this.currentDay
        payload.birthdayHour = parseInt(this.birthHour)

        this.$store.commit('fortune/setRequestData', payload)

        this.$router.push({ name: 'Preview' })
      } else {
        if (!this.birthday || this.name == '' || this.name === null) {
          alert('请输入生日与姓名')
        } else if (this.name.length < 2 || this.name.length > 4) {
          alert('注意姓名长度为2-4的中文字符')
        } else if (this.birthHour == null) {
          alert('请选择时辰')
        } else if (this.gender == null) {
          alert('请选择性别')
        } else {
          alert('注意姓名长度为2-4的中文字符')
        }
      }
    },
    linkToHistory: function () {
      this.$router.push({ name: 'History' })
    },
    modalSubmit: function (year, month, day) {
      this.currentYear = year
      this.currentMonth = month
      this.currentDay = day
    },
  },
  computed: {
    shiChenList: function () {
      let result = { ...SHI_CHEN }
      delete result[0]
      delete result[23]
      return result
    },
    formValid: function () {
      if (this.name && !isChinese(this.name)) {
        return false
      } else if (this.birthday && this.name && this.birthHour && this.gender) {
        if (this.name.length <= 4 && this.name.length >= 2) {
          return true
        }
      }
      return false
    },

    birthday: function () {
      if (!this.currentYear || !this.currentMonth || !this.currentDay)
        return null
      return (
        this.currentYear +
        '-' +
        String(this.currentMonth).padStart(2, '0') +
        '-' +
        String(this.currentDay).padStart(2, '0')
      )
    },
  },
}

var isChinese = function (s) {
  var ret = true
  for (var i = 0; i < s.length; i++) {
    ret = ret && s.charCodeAt(i) >= 10000 //判断文本字符的unicode值
  }
  return ret
}
</script>

<style scoped>
.key-vision-mobile {
  display: none;
}

.heading1 {
  font-size: 36px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0;
}
.heading5 {
  font-size: 21px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0;
}
@media only screen and (max-width: 768px) {
  .key-vision-desktop {
    display: none;
  }
  .key-vision-mobile {
    display: block;
  }
  .heading1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0;
  }
}

@media (max-width: 576px) {
  .heading1 {
    font-size: 21px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0;
  }
  .heading5 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0;
  }
}

.date-input-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.history-button {
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
.form__submit {
  cursor: pointer;
  text-align: center;
}
.home {
  background-color: #f3efed;
}
.office_form-control {
  display: flex;
}
.form_label {
  margin-right: 16px;
}
.form_birth-btn {
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
}
.form_birth {
  position: relative;
}
.form_select {
  flex: 1;
}

.btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 16px;
  border-radius: 8px;
}
.btn-buy {
  margin: 0 auto;
  padding: 12px 60px;
  border-radius: 4px;
  color: #fff;
  width: 100%;
  background-color: #ef3900;
  /* max-width: 320px; */
  cursor: pointer;
}
.office_form p {
  font-size: 14px;
}
</style>
